import React from 'react'
import { StyledActionButton } from './styles'

interface ActionButtonProps {
    title: string | React.ReactNode
    action?: (data?: any) => void
    type: 'button' | 'submit' | 'reset'
    color?: string
    fullWidth?: boolean
}

const ActionButton: React.FC<ActionButtonProps> = ({
    title,
    action,
    type,
    color,
    fullWidth = true,
}) => {
    return (
        <StyledActionButton
            onClick={action}
            type={type}
            color={color}
            fullWidth={fullWidth}
        >
            {title}
        </StyledActionButton>
    )
}
export default ActionButton
