import styled from 'styled-components'
import { FcGoogle } from 'react-icons/fc'

export const StyledGoogleButton = styled.button`
    padding: 8px 0;
    border: none;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colours.background.primary};
    font-size: 16px;
    color: ${({ theme }) => theme.colours.font.grey};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const StyledGoogleIcon = styled(FcGoogle)`
    margin-right: 8px;
`
