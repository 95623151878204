import styled from 'styled-components'

export const StyledErrorModal = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: ${({ theme }) =>
        theme.colours.background.transparent_black};
`

export const StyledError = styled.div`
    width: 50%;
    min-height: 150px;
    background-color: ${({ theme }) => theme.colours.background.primary};
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: 0px 0px 6px
        ${({ theme }) => theme.colours.background.transparent_black};
    box-shadow: 0px 0px 6px
        ${({ theme }) => theme.colours.background.transparent_black};
    border-radius: 4px;
    padding: 20px 20px;
    font-size: 15px;
`

export const StyledErrorContent = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    color: black;
    height: 100%;
    width: 100%;
    padding: 8px 0;
`

export const StyledClose = styled.div`
    height: 40px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${({ theme }) => theme.colours.background.red};
    border-radius: 4px;
    color: ${({ theme }) => theme.colours.font.primary};
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
    text-align: center;
    &:hover {
        opacity: 0.75;
    }
`
