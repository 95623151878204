import React, { useState } from 'react'
import { TbRefresh } from 'react-icons/tb'
import OutsideAlerter from '../../hooks/ClickOutside'
import {
    StyledRefreshFunctions,
    StyledMoreContainer,
    StyledDropDownMenu,
    StyledMenuItem,
    StyledDivider,
} from './styles'
import { useTheme } from 'styled-components'

export default function RefreshFunctions(props: {
    refreshFunctions: (isAllSheets: boolean) => void
}) {
    const theme = useTheme()
    const { refreshFunctions } = props
    const [isOpened, SetIsOpened] = useState(false)

    return (
        <StyledRefreshFunctions
            style={
                Office.context.platform === Office.PlatformType.Mac
                    ? { right: '68px' }
                    : { right: '44px' }
            }
        >
            <StyledMoreContainer>
                <OutsideAlerter
                    onpress={() => {
                        SetIsOpened(false)
                    }}
                >
                    <TbRefresh
                        color={theme.colours.font.primary}
                        onClick={() => SetIsOpened(!isOpened)}
                    />
                    {isOpened ? (
                        <StyledDropDownMenu>
                            <StyledMenuItem
                                onClick={() => refreshFunctions(false)}
                            >
                                Refresh current tab
                            </StyledMenuItem>
                            <StyledDivider />
                            <StyledMenuItem
                                onClick={() => refreshFunctions(true)}
                            >
                                Refresh all tabs
                            </StyledMenuItem>
                        </StyledDropDownMenu>
                    ) : null}
                </OutsideAlerter>
            </StyledMoreContainer>
        </StyledRefreshFunctions>
    )
}
