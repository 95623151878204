import styled from 'styled-components'
import { AiFillLock } from 'react-icons/ai'

export const StyledTemplate = styled.div`
    padding: 12px;
    background-color: ${({ theme }) => theme.colours.background.blue};
    margin-bottom: 12px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
`

export const StyledTemplateOverlay = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) =>
        theme.colours.background.transparent_black};
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const StyledTemplateImage = styled.img`
    width: 100%;
    border-radius: 2px;
    margin-bottom: 8px;
`

export const StyledTemplateName = styled.div`
    font-weight: bold;
`

export const StyledTemplateDescription = styled.div`
    color: ${({ theme }) => theme.colours.font.primary};
    font-size: 12px;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
`

export const StyledTemplateTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`

export const StyledTemplateLockIcon = styled(AiFillLock)`
    height: 70px;
    width: 70px;
    margin-bottom: 74px;
`
