import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const StyledContainer = styled.div`
    position: relative;
    margin-bottom: 20px;
`

export const StyledGridContainer = styled.div<{ isExpandable: boolean }>`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
    border: 1px solid ${({ theme }) => theme.colours.border.secondary};
    border-radius: 8px;
    overflow: hidden;
    padding: 0 10px;
    font-size: 14px;
    padding-bottom: ${({ isExpandable }) => (isExpandable ? 8 : 0)};
`

export const StyledGridItem = styled.div`
    padding: 12px 4px;
    border-bottom: 1px solid ${({ theme }) => theme.colours.border.secondary};
    text-decoration: none;
    &:nth-last-child(-n + 5) {
        border-bottom: none;
    }
`

export const StyledLink = styled(NavLink)`
    color: ${({ theme }) => theme.colours.font.green_accent};
    text-decoration: none;
`

export const StyledToggleButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -10px;
`
