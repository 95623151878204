import styled from 'styled-components'

export const StyledContent = styled.div`
    padding: 0 20px;
    font-weight: 300;
    letter-spacing: 1px;
`

export const StyledHeader = styled.div`
    width: 100%;
    font-size: 40px;
    padding-top: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
`

export const StyledWhite = styled.div`
    color: ${({ theme }) => theme.colours.font.primary};
`

export const StyledGreen = styled.span`
    color: ${({ theme }) => theme.colours.font.green};
`

export const StyledLoginForm = styled.form`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 0;
`

export const StyledInputContainer = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
`

export const StyledInput = styled.input`
    width: 100%;
    height: 30px;
    padding: 10px 20px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid ${({ theme }) => theme.colours.border.primary};
    border-radius: 4px;
    box-sizing: content-box;
    margin-bottom: 4px;
    &:focus {
        outline: none;
    }
`

export const StyledShowPasswordButton = styled.button`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: none;
    cursor: pointer;
`

export const StyledLoginButton = styled.div`
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
`

export const StyledOrContainer = styled.div`
    display: flex;
    padding-bottom: 20px;
`

export const StyledOr = styled.span`
    margin: 0 16px;
    color: ${({ theme }) => theme.colours.font.primary};
`

export const StyledLine = styled.span`
    flex-grow: 1;
    background: linear-gradient(
        ${({ theme }) => theme.colours.background.primary},
        ${({ theme }) => theme.colours.background.primary}
    );
    background-position: 0% 50%;
    background-size: 100% 1px;
    background-repeat: repeat-x;
`

export const StyledNewUser = styled.div`
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    padding: 20px 0;
    color: ${({ theme }) => theme.colours.font.light_grey};
    font-size: 11px;
    line-height: 16px;
`

export const StyledLink = styled.a`
    text-decoration: none;
    color: ${({ theme }) => theme.colours.font.primary};
    -webkit-transition: color 0.2s;
    -o-transition: color 0.2s;
    transition: color 0.2s;
    &:hover {
        color: ${({ theme }) => theme.colours.font.green};
    }
`

export const StyledValueProp = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: ${({ theme }) => theme.colours.font.primary};
    text-align: center;
`

export const StyledPolicies = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-between;
`
export const StyledOneTimePasscode = styled.div`
    margin-top: 8px;
`
