import styled from 'styled-components'

export const StyledOverlay = styled.div`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: ${({ theme }) =>
        theme.colours.background.transparent_black};
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledPopupContainer = styled.div`
    background-color: ${({ theme }) => theme.colours.background.primary};
    width: 90%;
    border-radius: 4px;
    color: ${({ theme }) => theme.colours.font.black};
    z-index: 1001;
    overflow: hidden;
    padding: 12px 0px 0px;
    box-sizing: border-box;
`

export const StyledPopupContent = styled.div`
    padding: 8px 8px 0 8px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    -ms-overflow-style: scrollbar;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        display: block;
    }
`

export const StyledFilterOptionContainer = styled.div`
    height: 400px;
`

export const StyledFilterOption = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
`

export const StyledCheckBox = styled.input`
    margin: 4px 8px 0 0;
    accent-color: ${({ theme }) => theme.colours.font.green};
    cursor: pointer;
    z-index: 500;
`

export const StyledLyOrLqInput = styled.input`
    border: none;
    outline: none;
    margin-top: 4px;
    width: 75%;
    border-bottom: 1px solid gray;
`

export const StyledSaveButton = styled.div`
    display: flex;
    align-items: center;
    padding: 12px;
    color: ${({ theme }) => theme.colours.font.green};
    cursor: pointer;
`
