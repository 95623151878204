import styled from 'styled-components'
import { TiArrowSortedDown } from 'react-icons/ti'

export const StyledPageDescription = styled.div`
    font-size: 14px;
    padding: 16px 0;
`

export const StyledTypeSelectContainer = styled.div`
    margin: 0 0 12px 0;
    position: relative;
`

export const StyledTypeSelect = styled.select`
    width: 100%;
    padding: 12px;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid ${({ theme }) => theme.colours.border.primary};
    border-radius: 6px;
    color: ${({ theme }) => theme.colours.font.blue};
    font-weight: 500;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
`

export const StyledCustomIcon = styled(TiArrowSortedDown)`
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 14px;
    color: ${({ theme }) => theme.colours.font.blue};
`

export const StyledDataTypeRadios = styled.div`
    background-color: ${({ theme }) => theme.colours.background.primary};
    padding: 0 12px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 44px;
`

export const StyledDataTypeLabel = styled.div`
    margin-right: auto;
    color: ${({ theme }) => theme.colours.font.blue};
    font-weight: 500;
    font-size: 14px;
`

export const StyledDataTypeRadio = styled.input`
    color: ${({ theme }) => theme.colours.font.blue};
    background-color: ${({ theme }) => theme.colours.background.dark_blue};
    cursor: pointer;
    margin: 0;
    &:checked {
        background-color: ${({ theme }) => theme.colours.background.dark_blue};
    }
`

export const StyledAnnualLabel = styled.label`
    color: ${({ theme }) => theme.colours.font.blue};
    margin-left: 2px;
    cursor: pointer;
    margin-right: 12px;
    font-size: 14px;
`

export const StyledQuarterLabel = styled.label`
    color: ${({ theme }) => theme.colours.font.blue};
    margin-left: 2px;
    cursor: pointer;
    font-size: 14px;
`

export const StyledCollapsibleContainer = styled.div`
    padding-top: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colours.font.blue_light};
`

export const StyledCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`

export const StyledActionButtonContainer = styled.div`
    padding: 14px 0 16px 0;
`

export const StyledDivider = styled.hr`
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colours.font.blue_light};
    margin: 20px 0;
    height: 0;
`

export const StyledTrendingStocksHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
`

export const StyledViewAllStocksButtonContainer = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StyledInfo = styled.div`
    color: ${({ theme }) => theme.colours.font.primary};
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 200;
`

export const StyledFullVersionContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-bottom: 16px;
`

export const StyledFullVersionButton = styled.button`
    padding: 2% 4%;
    border: none;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colours.background.blue};
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    font-size: 14px;
    color: ${({ theme }) => theme.colours.font.primary};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

export const StyledAutoSuggestWrapper = styled.div`
    .react-autosuggest__container {
        position: relative;
        display: flex;
        justify-content: center;
        padding-bottom: 12px;
    }

    .react-autosuggest__input {
        width: 100%;
        height: 44px;
        padding: 0 12px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-size: 14px;
        border: 1px solid ${({ theme }) => theme.colours.border.primary};
        border-radius: 6px;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 47px;
        width: 100%;
        border: 1px solid ${({ theme }) => theme.colours.border.primary};
        background-color: ${({ theme }) => theme.colours.background.primary};
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-size: 14px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
        color: ${({ theme }) => theme.colours.font.black};
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: ${({ theme }) => theme.colours.background.grey};
    }
`
