import React from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import {
    Success,
    SuccessContainer,
    SuccessContent,
    SuccessClose,
} from './styles'
import { useTheme } from 'styled-components'
type Props = {
    message: string
    onDismissClick: () => void
}

export default function SuccessModal(props: Props) {
    if (props.message === '') return null

    const theme = useTheme()

    return (
        <SuccessContainer>
            <Success>
                <AiFillCheckCircle color={theme.colours.font.green} size={36} />
                <SuccessContent
                    dangerouslySetInnerHTML={{ __html: props.message }}
                />
                <SuccessClose onClick={props.onDismissClick}>
                    Dismiss
                </SuccessClose>
            </Success>
        </SuccessContainer>
    )
}
