import styled from 'styled-components'

export const StyledRefreshFunctions = styled.div`
    position: absolute;
    right: 44px;
    top: 20px;
    color: ${({ theme }) => theme.colours.background.primary};
    font-size: 18px;
    cursor: pointer;
    z-index: 2;
`

export const StyledMoreContainer = styled.div`
    position: relative;
`

export const StyledDropDownMenu = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.colours.background.primary};
    border-radius: 6px;
    margin-top: 4px;
    right: 0;
    z-index: 1000;
    cursor: auto;
    padding: 12px;
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colours.background.grey};
`

export const StyledMenuItem = styled.div`
    color: ${({ theme }) => theme.colours.font.blue};
    font-size: 12px;
    text-wrap: nowrap;
    text-align: left;
    cursor: pointer;
`

export const StyledDivider = styled.hr`
    border: none;
    border-top: 1px solid ${({ theme }) => theme.colours.font.light_grey};
    margin: 8px 0;
`
