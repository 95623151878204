import React, { useRef } from 'react'
import { FaAngleDoubleDown } from 'react-icons/fa'
import {
    StyledAutoSuggestContainer,
    StyledAutoSuggestSelections,
    StyledInput,
    StyledScrollDownIcon,
} from './styles'

type AutoSuggestionsProps = {
    width: number
    inputRef: React.RefObject<HTMLInputElement>
    inputValue: string

    onInputBlur: () => void
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    onInputFocus: (e: React.FocusEvent<HTMLInputElement>) => void

    placeholder: string

    currentSuggestions: any[]
    renderSuggestion: (suggestion: any) => React.ReactNode
}

export default function AutoSuggestions(props: AutoSuggestionsProps) {
    const {
        width,
        currentSuggestions,
        inputRef,
        inputValue,
        onInputBlur,
        onInputChange,
        placeholder,
        onInputFocus,
    } = props
    const scrollRef = useRef<HTMLDivElement>(null)
    return (
        <StyledAutoSuggestContainer width={width}>
            <StyledInput
                width={width}
                ref={inputRef}
                onBlur={onInputBlur}
                value={inputValue}
                placeholder={placeholder}
                onChange={(e) => {
                    scrollRef.current?.scrollTo(0, 0)
                    onInputChange(e)
                }}
                onFocus={onInputFocus}
            />
            {currentSuggestions.length > 0 && (
                <StyledAutoSuggestSelections width={width} ref={scrollRef}>
                    {currentSuggestions.map((suggestion, index) =>
                        props.renderSuggestion(suggestion)
                    )}
                    <StyledScrollDownIcon>
                        <FaAngleDoubleDown color={'grey'} />
                    </StyledScrollDownIcon>
                </StyledAutoSuggestSelections>
            )}
        </StyledAutoSuggestContainer>
    )
}
