import React from 'react'
import PageHeader from '../PageHeader/PageHeader'
import Nav from '../Nav/Nav'
import { Outlet } from 'react-router-dom'
import { StyledPageRoot } from './styles'

export default function PageRoot() {
    return (
        <StyledPageRoot>
            <PageHeader />
            <Nav />
            <Outlet />
        </StyledPageRoot>
    )
}
