import React, { useEffect, useState } from 'react'
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'

// App pages
import Login from '../pages/Login/Login'
import StatementDump from '../pages/StatementDump/StatementDump'
import Templates from '../pages/Templates/Templates'
import PasswordlessLogin from '../pages/PasswordlessLogin/PasswordlessLogin'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import {
    useAuthStore,
    useCCExpireStore,
    useErrorStore,
    useLoadingStore,
    useSuccessStore,
} from '../state/store'
import LoadingModal from '../components/LoadingModal/LoadingModal'
import ErrorModal from '../components/ErrorModal/ErrorModal'
import { GoogleOAuthProvider } from '@react-oauth/google'
import Sso from '../pages/Sso/Sso'
import { httpClient } from '../utils/http'
import { Screener } from '../pages/Screener/Screener'
import SuccessModal from '../components/SuccessModal/SuccessModal'
import { FunctionBuilder } from '../pages/FunctionBuilder/FunctionBuilder'
import PageRoot from '../components/PageRoot/PageRoot'
import CompanyProfile from '../pages/CompanyProfile/CompanyProfile'
import TrendingStocks from '../pages/TrendingStocks/TrendingStocks'
import { ThemeProvider } from 'styled-components'
import { wisesheetsTheme } from '../utils/theme'
import { GlobalStyles } from './styles'

const queryClient = new QueryClient()

function usePageViews() {
    let location = useLocation()
    const [lastLocation, setLastLocation] = useState('')
    const { token, setAuth } = useAuthStore((state) => state)

    const checkAuthStatus = async () => {
        try {
            const response: { data: { token: string } } = await httpClient.post(
                '/public/auth/validate-auth-status',
                {
                    token: token,
                    platform: 'EXCEL',
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            if (response.data.token !== undefined) {
                window.localStorage.setItem('token', response.data.token)
                setAuth(response.data.token)
            }
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        if (lastLocation !== location.pathname) {
            setLastLocation(location.pathname)
            // call the api to validate auth/subscription status for all routes that are NOT /
            if (location.pathname !== '/') checkAuthStatus()
        }
    }, [location])
}

const growthbook = new GrowthBook({
    apiHost: 'https://cdn.growthbook.io',
    clientKey: 'sdk-AcOJiPy1IXjqHKGa',
    enableDevMode: window.location.hostname === 'localhost',
    // trackingCallback: (experiment, result) => {
    //     // TODO: Use your real analytics tracking system
    //     console.log('Viewed Experiment', {
    //         experimentId: experiment.key,
    //         variationId: result.key,
    //     })
    // },
})

export default function App() {
    const { currentUser } = useAuthStore((state) => state)
    const { setSubscriptionCCStatus } = useCCExpireStore((state) => state)
    const { loading } = useLoadingStore((state) => state)
    const { error, setError } = useErrorStore((state) => state)
    const { message, setSuccessMessage } = useSuccessStore((state) => state)
    const navigate = useNavigate()
    usePageViews()

    useEffect(() => {
        // this is how we deeplink to other pages do not remove as sso uses it to fuck shit up
        const route = window.location.href.split('#')[1]
        navigate(route)
    }, [])

    useEffect(() => {
        // Load features asynchronously when the app renders
        growthbook.loadFeatures({ autoRefresh: true })
    }, [])

    useEffect(() => {
        if (currentUser.email == '') return
        growthbook.setAttributes({
            id: currentUser.userId,
            email: currentUser.email,
        })
        async function fetchData() {
            try {
                const response = await httpClient.get(
                    '/api/private/stripe/subscription-status'
                )
                setSubscriptionCCStatus(response.data.expiring)
            } catch (e) {
                console.error(e)
            }
        }
        fetchData()
    }, [currentUser])

    return (
        <ThemeProvider theme={wisesheetsTheme}>
            <GlobalStyles />
            <GrowthBookProvider growthbook={growthbook}>
                <GoogleOAuthProvider clientId="301997072637-p0ch4m908fo0971kj9i0l2jjdon8fh44.apps.googleusercontent.com">
                    <QueryClientProvider client={queryClient}>
                        <LoadingModal
                            message={loading.message}
                            isShown={loading.isLoading}
                        />
                        <ErrorModal
                            message={error.errorMessage}
                            onDismissClick={() => {
                                setError({ hasError: false, errorMessage: '' })
                            }}
                            isShown={error.hasError}
                        />
                        <SuccessModal
                            message={message}
                            onDismissClick={() => {
                                setSuccessMessage('')
                            }}
                        />
                        <Routes>
                            <Route path="/feature" element={<PageRoot />}>
                                <Route
                                    path="templates"
                                    element={<Templates />}
                                />
                                <Route path="screener" element={<Screener />} />
                                <Route
                                    path="function-builder"
                                    element={<FunctionBuilder />}
                                />
                                <Route
                                    path="statement-dump"
                                    element={<StatementDump />}
                                />
                                <Route
                                    path="company-profile/:symbol"
                                    element={<CompanyProfile />}
                                />
                                <Route
                                    path="trending-stocks"
                                    element={<TrendingStocks />}
                                />
                            </Route>
                            <Route
                                path="/passwordless-login"
                                element={<PasswordlessLogin />}
                            />

                            <Route path="/sso" element={<Sso />} />
                            <Route path="/" element={<Login />} />
                        </Routes>
                    </QueryClientProvider>
                </GoogleOAuthProvider>
            </GrowthBookProvider>
        </ThemeProvider>
    )
}
