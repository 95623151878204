import React from 'react'
import { AiFillTool } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { StyledRefreshErrors } from './styles'
import { useTheme } from 'styled-components'

export default function RefreshErrors(props: { refreshErrors: () => void }) {
    const { refreshErrors } = props
    const theme = useTheme()
    return (
        <StyledRefreshErrors
            style={
                Office.context.platform === Office.PlatformType.Mac
                    ? { right: '40px' }
                    : { right: '16px' }
            }
        >
            <AiFillTool
                data-for="refresh-errors"
                data-tip="Refresh errors"
                color={theme.colours.font.primary}
                onClick={refreshErrors}
            />
            <ReactTooltip id="refresh-errors" place="left" />
        </StyledRefreshErrors>
    )
}
