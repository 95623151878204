import styled from 'styled-components'

export const StyledLoadingModal = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: ${({ theme }) =>
        theme.colours.background.transparent_black};
`

export const StyledMessage = styled.p`
    color: ${({ theme }) => theme.colours.font.primary};
`
