import { PERIOD_OPTIONS } from '../constants'
import React from 'react'
import {
    StyledCheckBox,
    StyledFilterOption,
    StyledFilterOptionContainer,
    StyledLyOrLqInput,
    StyledOverlay,
    StyledPopupContainer,
    StyledPopupContent,
    StyledSaveButton,
} from './styles'
import { useTheme } from 'styled-components'

type Props = {
    selectedCategories: CATEGORY_NAMES[]
    allowedPeriods: DATE_SELECTIONS[]
    selectedItems: PERIOD_OPTION[]
    onItemSelected: (item: any) => void
    onSavePressed: () => void
    isShown: boolean
    onExternalClick: () => void
}

export const PeriodModal = (props: Props) => {
    const theme = useTheme()
    const parseNumberList = (inputString: string): number[] => {
        if (inputString.trim() === '') {
            return [] // Return an empty array if the input string is empty
        }

        const parts = inputString
            .split(',') // Split the string by commas
            .map((s) => s.trim()) // Trim each part

        if (parts[parts.length - 1] === '') {
            parts.pop() // Remove the last part if it is empty
        }

        // Check if any part is invalid
        if (parts.some((part) => part === '' || isNaN(Number(part)))) {
            return [] // Return an empty array if any part is invalid
        }

        return parts.map(Number).sort() // Convert remaining valid strings to numbers
    }

    const handleLYorLQChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        type: 'LQ-N' | 'LY-N'
    ) => {
        if (!e.target.value || e.target.value === '') {
            props.onItemSelected({
                name: type,
                // adding this field is a hack so we know we're handling LY
                values: [],
            })
        }
        const result = parseNumberList(e.target.value)
        if (result.length > 0) {
            props.onItemSelected({
                name: type,
                // adding this field is a hack so we know we're handling LY
                values: result,
            })
        }
    }

    if (!props.isShown) {
        return null
    }

    return (
        <StyledOverlay onClick={() => props.onExternalClick()}>
            <StyledPopupContainer onClick={(e) => e.stopPropagation()}>
                <StyledPopupContent>
                    <StyledFilterOptionContainer>
                        {PERIOD_OPTIONS.filter((periodOption) =>
                            props.selectedCategories.includes(
                                'analyst_estimates'
                            )
                                ? true // return everything if the selected category is 'analyst_estimates'
                                : // filter future periods if no 'analyst_estimates'
                                  periodOption.type != 'SET_YEAR_FUTURE'
                        )
                            .filter((selection) => {
                                return props.allowedPeriods.includes(
                                    selection.type
                                )
                            })
                            .map((periodOption, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginBottom: 12,
                                        }}
                                    >
                                        <StyledFilterOption>
                                            <div>
                                                {/*LY-N and LQ-N are controlled by the CSV input and don't need a radio*/}
                                                {periodOption.name !== 'LY-N' &&
                                                    periodOption.name !==
                                                        'LQ-N' && (
                                                        <StyledCheckBox
                                                            checked={props.selectedItems
                                                                .map(
                                                                    (item) =>
                                                                        item.id
                                                                )
                                                                .includes(
                                                                    periodOption.id
                                                                )}
                                                            value={
                                                                periodOption.name
                                                            }
                                                            type="checkbox"
                                                            onChange={(e) => {
                                                                props.onItemSelected(
                                                                    periodOption
                                                                )
                                                            }}
                                                            id={`${periodOption.id}_checkbox`}
                                                        />
                                                    )}
                                                <label
                                                    htmlFor={`${periodOption}_checkbox`}
                                                >
                                                    {periodOption.name}
                                                </label>
                                            </div>

                                            <span
                                                style={{
                                                    fontSize: 10,
                                                    letterSpacing: 0.5,
                                                    color: theme.colours.font
                                                        .dark_grey,
                                                }}
                                            >
                                                {periodOption.description}
                                            </span>
                                        </StyledFilterOption>
                                        {/*Add the CSV inputs for LY and LQ*/}
                                        {['LY-N'].includes(
                                            periodOption.name
                                        ) && (
                                            <StyledLyOrLqInput
                                                type={'text'}
                                                placeholder={
                                                    'N-values comma separated'
                                                }
                                                onChange={(e) => {
                                                    handleLYorLQChange(
                                                        e,
                                                        'LY-N'
                                                    )
                                                }}
                                            />
                                        )}
                                        {['LQ-N'].includes(
                                            periodOption.name
                                        ) && (
                                            <StyledLyOrLqInput
                                                type={'text'}
                                                placeholder={
                                                    'N-values comma separated'
                                                }
                                                onChange={(e) => {
                                                    handleLYorLQChange(
                                                        e,
                                                        'LQ-N'
                                                    )
                                                }}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                    </StyledFilterOptionContainer>
                </StyledPopupContent>
                <StyledSaveButton onClick={props.onSavePressed}>
                    Save
                </StyledSaveButton>
            </StyledPopupContainer>
        </StyledOverlay>
    )
}
