import { BsTrash3 } from 'react-icons/bs'
import { TbRefresh } from 'react-icons/tb'
import styled from 'styled-components'

export const StyledBucketContainer = styled.div`
    background-color: ${({ theme }) => theme.colours.background.tertiary};
    color: ${({ theme }) => theme.colours.font.black};
    padding: 8px;
    margin: 8px 0;
    border-radius: 4px;
`

export const StyledBucketTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const StyledExpandButton = styled.div`
    cursor: pointer;
    margin-right: 6px;
`

export const StyledBucketName = styled.input`
    border: none;
    padding: 8px 4px;
    background-color: ${({ theme }) => theme.colours.background.tertiary};
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    margin-right: 8px;
    border-radius: 2px;
    &:hover {
        background-color: ${({ theme }) => theme.colours.font.light_grey};
    }
`

export const StyledRangeContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledRangeInputs = styled.input`
    border: none;
    padding: 8px 4px;
    background-color: ${({ theme }) => theme.colours.background.tertiary};
    font-size: 14px;
    cursor: pointer;
    border-radius: 2px;
    &:hover {
        background-color: ${({ theme }) => theme.colours.font.light_grey};
    }
`

export const StyledLabel = styled.label`
    color: ${({ theme }) => theme.colours.font.grey};
`

export const StyledRefresh = styled(TbRefresh)`
    cursor: pointer;
    font-size: 20px;
`

export const StyledTrash = styled(BsTrash3)`
    cursor: pointer;
    font-size: 20px;
    margin-left: 8px;
`
