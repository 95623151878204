import styled from 'styled-components'

export const StyledRefreshErrors = styled.div`
    position: absolute;
    right: 16px;
    top: 20px;
    color: ${({ theme }) => theme.colours.font.primary};
    font-size: 18px;
    cursor: pointer;
    z-index: 2;
`
