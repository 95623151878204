import React, { useEffect, useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import LoadingModal from '../../components/LoadingModal/LoadingModal'
import { SsoContainer } from './styles'

export default function Sso() {
    // *********** state *********** //
    const [isLoading, setIsLoading] = useState(true)

    const login = useGoogleLogin({
        flow: 'auth-code',
        ux_mode: 'redirect',
    })
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false)
            login()
        }, 1000)
    }, [])
    return (
        <SsoContainer>
            {isLoading ? (
                <LoadingModal message={''} isShown={isLoading} />
            ) : null}
        </SsoContainer>
    )
}
