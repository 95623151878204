import styled from 'styled-components'

export const StyledCollapsibleContainer = styled.div<{ isOpened: boolean }>`
    border-radius: 6px 6px 0 0;
    font-size: 14px;
    background-color: ${({ theme, isOpened }) =>
        isOpened ? theme.colours.background.blue : null};
`

export const StyledLabelContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 6px 6px 0 0;
    &:hover {
        background-color: ${({ theme }) => theme.colours.background.blue};
    }
`

export const StyledChildrenContainer = styled.div`
    padding: 8px;
`
