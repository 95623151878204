import styled from 'styled-components'

export const StyledNavItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
`

export const StyledNavIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.colours.background.primary};
    padding: 8px;
    font-size: 24px;
    border-radius: 100%;
    margin-bottom: 4px;
`

export const StyledNavName = styled.div`
    color: ${({ theme }) => theme.colours.font.primary};
    font-size: 10px;
`

export const StyledMoreContainer = styled.div`
    position: relative;
    cursor: pointer;
`

export const StyledDropDownMenu = styled.div`
    position: absolute;
    background-color: ${({ theme }) => theme.colours.background.primary};
    border-radius: 4px;
    margin-top: 4px;
    right: 8px;
    z-index: 1000;
    cursor: auto;
    box-shadow: 0px 0px 10px 0px
        ${({ theme }) => theme.colours.background.transparent_black};
`

export const StyledDropDownLink = styled.a`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.colours.font.grey};
    margin: 16px;
    transition: color 0.2s ease;
    width: 150px;
    &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.colours.font.green};
    }
`

export const StyledDropDownLinkIcon = styled.div`
    margin-right: 6px;
    font-size: 24px;
`

export const StyledNavContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
