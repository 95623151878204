export const WISESHEETS_API_URL = 'https://api.wisesheets.io'
// export const WISESHEETS_API_URL = 'http://localhost:4000'
export const EXCHANGE_PRIORITY_LIST = [
    'NYSE',
    'NASDAQ',
    'EURONEXT',
    'JPX',
    'SHH',
    'SHZ',
    'HKSE',
    'NSE',
    'LSE',
    'SAU',
    'TSX',
    'XETRA',
    'SIX',
    'KOE',
    'TAI',
    'ASX',
    'JNB',
    'MCX',
    'SAO',
    'SES',
    'MIL',
    'BME',
    'OSL',
    'TLV',
    'HOSE',
    'WSE',
    'SET',
    'BRU',
    'AMS',
    'BUE',
    'BUD',
    'PRA',
    'RIS',
    'DFM',
    'DOH',
    'BVC',
    'HEL',
    'HAM',
    'STO',
    'STU',
    'BER',
    'MEX',
    'NEO',
    'OTC',
    'PNK',
    'BSE',
    'AQS',
    'CPH',
    'JKT',
    'KUW',
    'DXE',
    'EGX',
    'CBOE',
    'OEM',
    'IOB',
    'MUN',
    'VIE',
    'TSXV',
    'TWO',
    'ATH',
    'ICE',
    'KUW',
    'LSE',
    'BME',
    'MEX',
    'WSE',
    'BRU',
    'AMS',
    'BUE',
    'BUD',
    'PRA',
    'RIS',
]
