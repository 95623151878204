import React from 'react'
import {
    useAuthStore,
    useCCExpireStore,
    useErrorStore,
    useLoadingStore,
} from '../../state/store'
import RefreshFunctions from '../RefreshFunctions/RefreshFunctions'
import RefreshErrors from '../RefreshErrors/RefreshErrors'
import { IoIosCloseCircle } from 'react-icons/io'
import { StyledWhite } from './styles'
import { StyledGreen } from './styles'
import { StyledHeading } from './styles'
import { StyledExpiringContainer } from './styles'
import { StyledCloseButton } from './styles'

export default function PageHeader() {
    const { setSubscriptionCCStatus, status } = useCCExpireStore(
        (state) => state
    )

    const { setLoading } = useLoadingStore((state) => state)
    const { setError } = useErrorStore((state) => state)

    const reevaluateLiveData = async (isAllSheets: boolean) => {
        setLoading({ isLoading: true, message: 'Updating data...' })
        try {
            await Excel.run(async (context) => {
                if (isAllSheets) {
                    const sheets = context.workbook.worksheets

                    sheets.load('items/name')

                    await context.sync()

                    sheets.items.forEach(function (sheet) {
                        const foundRanges = sheet.findAllOrNullObject(
                            'WISEPRICE',
                            {
                                completeMatch: false, // find will match the whole cell value
                                matchCase: false, // find will not match case
                            }
                        )
                        foundRanges.calculate()
                        const foundRangesTTM = sheet.findAllOrNullObject(
                            'TTM',
                            {
                                completeMatch: false, // find will match the whole cell value
                                matchCase: false, // find will not match case
                            }
                        )

                        foundRangesTTM.calculate()
                        const foundRangesFunds = sheet.findAllOrNullObject(
                            'WISEFUNDS',
                            {
                                completeMatch: false, // find will match the whole cell value
                                matchCase: false, // find will not match case
                            }
                        )

                        foundRangesFunds.calculate()
                    })
                } else {
                    // create the new sheet
                    const currentSheet =
                        context.workbook.worksheets.getActiveWorksheet()
                    const foundRanges = currentSheet.findAllOrNullObject(
                        'WISEPRICE',
                        {
                            completeMatch: false, // find will match the whole cell value
                            matchCase: false, // find will not match case
                        }
                    )
                    foundRanges.calculate()
                    const foundRangesTTM = currentSheet.findAllOrNullObject(
                        'TTM',
                        {
                            completeMatch: false, // find will match the whole cell value
                            matchCase: false, // find will not match case
                        }
                    )

                    foundRangesTTM.calculate()
                    const foundRangesFunds = currentSheet.findAllOrNullObject(
                        'WISEFUNDS',
                        {
                            completeMatch: false, // find will match the whole cell value
                            matchCase: false, // find will not match case
                        }
                    )

                    foundRangesFunds.calculate()
                }

                await context.sync()
            })
        } catch (error) {
            setError({
                hasError: true,
                errorMessage: 'Something went wrong. Please try again.',
            })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }

    const refreshErrors = async () => {
        setLoading({ isLoading: true, message: 'Refreshing errors...' })
        try {
            await Excel.run(async (context) => {
                // create the new sheet
                var currentSheet =
                    context.workbook.worksheets.getActiveWorksheet()
                var foundRanges = currentSheet.findAllOrNullObject(
                    'Too many simultaneous requests.',
                    {
                        completeMatch: false, // find will match the whole cell value
                        matchCase: false, // find will not match case
                    }
                )
                foundRanges.calculate()

                var foundRanges2 = currentSheet.findAllOrNullObject('#VALUE!', {
                    completeMatch: false, // find will match the whole cell value
                    matchCase: false, // find will not match case
                })
                foundRanges2.calculate()

                var foundRanges3 = currentSheet.findAllOrNullObject(
                    'Unexpected result. Verify your function call and retry.',
                    {
                        completeMatch: false, // find will match the whole cell value
                        matchCase: false, // find will not match case
                    }
                )
                foundRanges3.calculate()

                await context.sync()
            })
        } catch (error) {
            setError({
                hasError: true,
                errorMessage: 'Something went wrong. Please try again.',
            })
        } finally {
            setLoading({ isLoading: false, message: '' })
        }
    }
    return (
        <>
            <StyledHeading>
                <StyledWhite>
                    Wise
                    <StyledGreen>sheets</StyledGreen>
                </StyledWhite>
                <RefreshFunctions refreshFunctions={reevaluateLiveData} />
                <RefreshErrors refreshErrors={refreshErrors} />
            </StyledHeading>
            {status.expiring ? (
                <StyledExpiringContainer>
                    <div>⚠️ Your plan is expiring soon</div>
                    <a
                        href="https://wisesheets.io/manage-account"
                        target="_blank"
                    >
                        Update billing
                    </a>
                    <StyledCloseButton
                        onClick={() => setSubscriptionCCStatus(false)}
                    >
                        <IoIosCloseCircle size={24} />
                    </StyledCloseButton>
                </StyledExpiringContainer>
            ) : null}
        </>
    )
}
