import styled from 'styled-components'

export const StyledPageRoot = styled.div`
    min-height: 100vh;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colours.background.dark_blue};
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colours.font.tertiary};
    font-size: 15px;
    overflow-y: scroll;
    padding: 0 16px;
`
