import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    html, body {
        height: 100%;
        width: 100%;
        background-color: ${({ theme }) => theme.colours.background.dark_blue};
        margin: 0;
        padding: 0;
        font-family: Roboto, sans-serif;
    }
    section {
        width: inherit;
    }
    *::-webkit-scrollbar {
        display: none;
    }

    * {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
`
