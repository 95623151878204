import styled from 'styled-components'

export const StyledAutoSuggestContainer = styled.div<{ width: number }>`
    width: ${({ width }) => width}px;
`

export const StyledInput = styled.input<{ width: number }>`
    height: 10px;
    padding: 12px 8px;
    font-weight: 300;
    font-size: 14px;
    border: 1px solid ${({ theme }) => theme.colours.border.primary};
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    letter-spacing: 0.5px;
    width: ${({ width }) => width}px;
    margin-top: 6px;
    font-family: Roboto, sans-serif;
`

export const StyledAutoSuggestSelections = styled.div<{ width: number }>`
    background-color: ${({ theme }) => theme.colours.background.primary};
    position: absolute;
    z-index: 100;
    box-sizing: border-box;
    overflow-y: scroll;
    -ms-overflow-style: scrollbar;
    scrollbar-width: thin;
    width: ${({ width }) => width}px;
    height: 300px;
    ::-webkit-scrollbar {
        display: block;
    }
    font-family: Roboto, sans-serif;
`

export const StyledScrollDownIcon = styled.div`
    position: sticky;
    bottom: 0;
    margin-left: 115px;
    z-index: 50000;
`
