import React from 'react'
import { StyledGoogleIcon } from './styles'
import { StyledGoogleButton } from './styles'

export default function GoogleButton(props: { onClick: () => void }) {
    return (
        <StyledGoogleButton onClick={props.onClick}>
            <StyledGoogleIcon size={18} />
            Sign in with Google
        </StyledGoogleButton>
    )
}
