import styled from 'styled-components'

export const StyledHeading = styled.div`
    font-size: 26px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: normal;
    text-align: center;
    flex-direction: column;
    padding: 14px 0 26px 0;
`

export const StyledWhite = styled.div`
    color: ${({ theme }) => theme.colours.font.primary};
`

export const StyledGreen = styled.span`
    color: ${({ theme }) => theme.colours.font.green};
`

export const StyledExpiringContainer = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colours.background.red};
    font-size: 13px;
    border-radius: 4px;
    padding: 8px 12px;
    margin-bottom: 24px;
    position: relative;
    a {
        color: ${({ theme }) => theme.colours.font.primary};
        text-decoration: underline;
        margin-left: auto;
    }
`

export const StyledCloseButton = styled.div`
    position: absolute;
    top: -13px;
    right: -13px;
    color: ${({ theme }) => theme.colours.font.primary};
    cursor: pointer;
`
