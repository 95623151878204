import styled from 'styled-components'
import { HiOutlineArrowLeft } from 'react-icons/hi'

export const StyledBackButton = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 24px 0;
`

export const StyledArrowLeft = styled(HiOutlineArrowLeft)`
    margin-right: 4px;
`

export const StyledQuoteInfo = styled.div`
    background-color: ${({ theme }) => theme.colours.background.blue};
    border: 1px solid ${({ theme }) => theme.colours.border.secondary};
    border-radius: 8px;
    padding: 16px 12px;
    margin-bottom: 24px;
`

export const StyledQuoteInfoHeading = styled.div`
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
`

export const StyledEllipse = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colours.background.secondary};
    outline: 2px solid ${({ theme }) => theme.colours.border.tertiary};
    margin: 0 8px;
`

export const StyledCurrency = styled.div`
    font-size: 10px;
    margin-left: 2px;
`

export const StyledDivider = styled.hr`
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.colours.font.white_accent};
    margin: 12px 0;
    height: 0;
`

export const StyledMetricsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 16px;
    grid-column-gap: 32px;
    position: relative;
    > .metric::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -16px;
        width: 1px;
        background-color: ${({ theme }) =>
            theme.colours.background.white_accent};
    }
    > .metric:nth-child(3n)::before {
        display: none;
    }
`

export const StyledCompanyNewsHeader = styled.div`
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
`

export const StyledContainer = styled.div`
    position: relative;
`

export const StyledArticleContainer = styled.div`
    background-color: ${({ theme }) => theme.colours.background.blue};
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px;
`

export const StyledArticle = styled.a`
    color: ${({ theme }) => theme.colours.font.primary};
    border: 1px solid ${({ theme }) => theme.colours.border.secondary};
    padding: 14px 12px;
    text-decoration: none;
    display: flex;
`

export const StyledArticleTitle = styled.div`
    font-size: 12px;
    margin-right: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const StyledToggleButton = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -10px;
`

export const StyledFilingContainer = styled.div`
    background-color: ${({ theme }) => theme.colours.background.blue};
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 24px;
`

export const StyledFiling = styled.div`
    border: 1px solid ${({ theme }) => theme.colours.border.secondary};
    padding: 14px 12px;
    display: flex;
    align-items: center;
    font-size: 12px;
`

export const StyledFilingLink = styled.a`
    color: ${({ theme }) => theme.colours.font.green_accent};
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-left: auto;
`
export const StyledMetric = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: relative;
`

export const StyledMetricValue = styled.div`
    font-size: 13px;
    margin-bottom: 6px;
    text-align: center;
`

export const StyledMetricName = styled.div`
    font-size: 10px;
    color: ${({ theme }) => theme.colours.font.white_accent_dark};
    text-align: center;
`
