import React from 'react'
import { ScaleLoader } from 'react-spinners'
import { useTheme } from 'styled-components'
import { StyledMessage } from './styles'
import { StyledLoadingModal } from './styles'

type Props = {
    message: string
    isShown: boolean
}

export default function LoadingModal(props: Props) {
    const theme = useTheme()
    if (!props.isShown) {
        return null
    } else
        return (
            <StyledLoadingModal>
                <ScaleLoader color={theme.colours.font.green} loading={true} />
                <StyledMessage>{props.message}</StyledMessage>
            </StyledLoadingModal>
        )
}
