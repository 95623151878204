import styled from 'styled-components'

export const StyledTrendingStocksHeader = styled.div`
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 20px;
    font-size: 14px;
`

export const StyledTrendingSubHeader = styled.div`
    font-size: 14px;
    margin-bottom: 12px;
`

export const StyledAutoSuggestWrapper = styled.div`
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: relative;
    }

    .input {
        padding: 8px 12px;
        font-size: 12px;
        border: 1px solid ${({ theme }) => theme.colours.border.grey};
        border-radius: 4px;
        box-sizing: border-box;
        width: 100%;
        background-color: ${({ theme }) => theme.colours.background.blue};
        color: ${({ theme }) => theme.colours.font.light_grey};
        margin-bottom: 20px;
    }

    .input::placeholder {
        color: ${({ theme }) => theme.colours.font.white_accent_dark};
        letter-spacing: 0.5px;
    }

    .input:focus {
        outline: none;
    }

    .suggestionsContainer {
        display: none;
    }

    .suggestionsContainerOpen {
        position: absolute;
        top: 32px;
        width: 100%;
        display: block;
        border: 1px solid ${({ theme }) => theme.colours.border.primary};
        background-color: ${({ theme }) => theme.colours.background.primary};
        font-weight: 300;
        font-size: 14px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
        color: ${({ theme }) => theme.colours.font.black};
    }

    .suggestionsList {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .suggestion:hover {
        background-color: ${({ theme }) => theme.colours.background.grey};
    }
    .react-autosuggest__container {
        position: relative;
        display: flex;
        justify-content: center;
        padding-bottom: 12px;
    }

    .react-autosuggest__input {
        width: 100%;
        height: 44px;
        padding: 0 12px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-size: 14px;
        border: 1px solid ${({ theme }) => theme.colours.border.primary};
        border-radius: 6px;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 47px;
        width: 100%;
        border: 1px solid ${({ theme }) => theme.colours.border.primary};
        background-color: ${({ theme }) => theme.colours.background.primary};
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-size: 14px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
        color: ${({ theme }) => theme.colours.font.black};
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: ${({ theme }) => theme.colours.background.grey};
    }
`
