import { RxGrid } from 'react-icons/rx'
import styled from 'styled-components'

export const StyledContainer = styled.div`
    padding-bottom: 50px;
`

export const StyledUnsubscribedBanner = styled.div`
    background-color: ${({ theme }) => theme.colours.background.blue};
    padding: 10px;
    border-radius: 4px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 12px;
`

export const StyledFilterContainer = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colours.background.primary};
    color: black;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 10px;
    border-radius: 4px;
    position: relative;
`

export const StyledFilter = styled.div`
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const StyledFilterTitle = styled.span`
    white-space: nowrap;
    font-size: 14px;
`

export const StyledArithmeticFilter = styled.select`
    border: none;
    font-size: 12px;
    outline: none;
    color: ${({ theme }) => theme.colours.font.green};
    cursor: pointer;
`

export const StyledRxGrid = styled(RxGrid)`
    position: absolute;
    right: 8px;
    cursor: pointer;
    font-size: 16px;
    color: ${({ theme }) => theme.colours.font.dark_grey};
`

export const StyledRangeInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    position: relative;
`

export const StyledInput = styled.input`
    height: 10px;
    padding: 12px 8px;
    font-weight: 300;
    border: 1px solid ${({ theme }) => theme.colours.border.primary};
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
`

export const StyledSelectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    font-size: 12px;
`

export const StyledSelectedValue = styled.div`
    padding: 4px 8px;
    background-color: ${({ theme }) => theme.colours.background.green_accent};
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-top: 8px;
`

export const StyledAutoSuggestionItem = styled.div`
    padding: 6px;
    box-sizing: border-box;
    &:hover {
        background-color: ${({ theme }) =>
            theme.colours.background.green_accent};
    }
`

export const StyledDateTitle = styled.span`
    white-space: nowrap;
    font-size: 14px;
`

export const StyledDateInputs = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 8px;
    & > * {
        display: flex;
    }
`

export const StyledAllDatesRadioContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`

export const StyledAllDatesRadio = styled.div`
    height: 10px;
    width: 10px;
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
    margin-right: 8px;
`

export const StyledAddPeriodButton = styled.span`
    color: ${({ theme }) => theme.colours.font.dark_grey};
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-left: 8px;
`

export const StyledGeneratedFunctionSpacer = styled.div`
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.colours.background.blue};
    margin-bottom: 12px;
`

export const StyledGeneratedFunction = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.colours.background.primary};
    color: black;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 12px;
    &:hover {
        background-color: ${({ theme }) => theme.colours.background.tertiary};
    }
`

export const StyledLink = styled.a`
    text-decoration: none;
    font-weight: bold;
    color: ${({ theme }) => theme.colours.font.green};
    margin-right: 5px;
`

export const StyledClickHere = styled.div`
    font-size: 12px;
    margin-bottom: 8px;
    width: '100%';
    text-align: center;
    position: relative;
    top: 40;
`

export const StyledFullVersionContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-bottom: 16px;
`

export const StyledFullVersionButton = styled.button`
    padding: 2% 4%;
    border: none;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colours.background.blue};
    -webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;
    font-size: 14px;
    color: ${({ theme }) => theme.colours.font.primary};
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

export const StyledAutoSuggestWrapper = styled.div`
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 90%;
        position: absolute;
        top: 32px;
    }

    .input {
        height: 10px;
        padding: 12px 8px;
        font-weight: 300;
        font-size: 14px;
        border: 1px solid ${({ theme }) => theme.colours.border.primary};
        border-radius: 4px;
        box-sizing: border-box;
    }
    .input::placeholder {
        color: ${({ theme }) => theme.colours.font.dark_grey};
        letter-spacing: 0.5px;
    }

    .input:focus {
        outline: none;
    }

    .suggestionsContainer {
        display: none;
        position: relative;
        bottom: 0;
    }

    .suggestionsContainerOpen {
        display: block;
        border: 1px solid ${({ theme }) => theme.colours.border.primary};
        background-color: ${({ theme }) => theme.colours.background.primary};
        font-weight: 300;
        font-size: 14px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
        color: ${({ theme }) => theme.colours.font.black};
    }

    .suggestionsList {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .suggestion:hover {
        background-color: ${({ theme }) => theme.colours.background.grey};
    }
    .react-autosuggest__container {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .react-autosuggest__input {
        width: 100%;
        height: 25px;
        padding: 0 8px;
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-size: 14px;
        border: 1px solid ${({ theme }) => theme.colours.border.primary};
        border-radius: 4px;
    }

    .react-autosuggest__input--focused {
        outline: none;
    }

    .react-autosuggest__input--open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .react-autosuggest__suggestions-container {
        display: none;
    }

    .react-autosuggest__suggestions-container--open {
        display: block;
        position: absolute;
        top: 47px;
        width: 100%;
        border: 1px solid ${({ theme }) => theme.colours.border.primary};
        background-color: ${({ theme }) => theme.colours.background.primary};
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-size: 14px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        z-index: 2;
        color: ${({ theme }) => theme.colours.font.black};
    }

    .react-autosuggest__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    .react-autosuggest__suggestion {
        cursor: pointer;
        padding: 10px 20px;
    }

    .react-autosuggest__suggestion--highlighted {
        background-color: ${({ theme }) => theme.colours.background.grey};
    }
`
