export type ColourSet = {
    primary: string
    secondary: string
    tertiary: string
}

export type FontColours = ColourSet & {
    black: string
    grey: string
    green: string
    green_accent: string
    blue: string
    blue_light: string
    blue_accent: string
    red: string
    red_accent: string
    light_grey: string
    dark_grey: string
    white_accent: string
    white_accent_dark: string
}

export type BackgroundColours = ColourSet & {
    blue: string
    red: string
    transparent_black: string
    dark_blue: string
    grey: string
    green_accent: string
    blue_accent: string
    white_accent: string
}

export type BorderColours = ColourSet & {
    grey: string
}

export type WisesheetsTheme = {
    colours: {
        font: FontColours
        background: BackgroundColours
        border: BorderColours
    }
}

export const wisesheetsTheme: WisesheetsTheme = {
    colours: {
        font: {
            primary: '#FFFFFF',
            secondary: '#ffffff80',
            tertiary: '#F5F5F5',
            black: '#000000',
            grey: '#232323',
            green: '#1FB723',
            green_accent: '#48d74b',
            blue: '#364165',
            blue_light: '#465484',
            blue_accent: '#6ccbf3',
            red: '#EC3434',
            red_accent: '#f65656',
            light_grey: '#ebebeb',
            dark_grey: '#aaaaaa',
            white_accent: '#ffffff10',
            white_accent_dark: '#ffffff80',
        },
        background: {
            primary: '#ffffff',
            secondary: '#1fb723',
            tertiary: '#f5f5f5',
            blue: '#465484',
            red: '#ec3434',
            transparent_black: '#00000030',
            dark_blue: '#364165',
            grey: '#ebebeb',
            green_accent: '#1fb72340',
            blue_accent: '#e0f0ff',
            white_accent: '#ffffff10',
        },
        border: {
            primary: '#aaaaaa',
            secondary: '#ffffff10',
            tertiary: '#1fb72340',
            grey: '#ebebeb',
        },
    },
}
