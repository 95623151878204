import React, { useEffect, useState } from 'react'
import {
    StyledTemplate,
    StyledTemplateOverlay,
    StyledTemplateImage,
    StyledTemplateName,
    StyledTemplateDescription,
    StyledTemplateTop,
    StyledTemplateLockIcon,
} from './styles'

type TemplateProps = {
    template: Template
    handleClick: (id: string, platform: string, url: string) => void
    isLocked: boolean // used for templates that are promotional and don't interface directly with the spreadsheet
}

function TemplateListItem(props: TemplateProps) {
    const { handleClick, template } = props

    const [currentTemplate, setCurrentTemplate] = useState<Template>(template)

    useEffect(() => {
        setCurrentTemplate(template)
    }, [template])

    return (
        <StyledTemplate
            onClick={() => {
                handleClick(
                    currentTemplate.id,
                    currentTemplate.platform,
                    currentTemplate.url
                )
            }}
        >
            {props.isLocked ? (
                <StyledTemplateOverlay>
                    <StyledTemplateLockIcon />
                </StyledTemplateOverlay>
            ) : null}

            <div>
                {currentTemplate.image_url ? (
                    <StyledTemplateImage src={currentTemplate.image_url} />
                ) : null}
            </div>
            <div>
                <StyledTemplateTop>
                    <StyledTemplateName>
                        {currentTemplate.name}
                    </StyledTemplateName>
                </StyledTemplateTop>
                <StyledTemplateDescription>
                    {currentTemplate.description}
                </StyledTemplateDescription>
            </div>
        </StyledTemplate>
    )
}

export default TemplateListItem
